import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const PrivateRoute = props => {
    const { user } = useAuth();

    return (
        user ?
            <React.Suspense fallback={props.loading()}>
                <Route
                    {...props}
                    render={props => <DefaultLayout {...props} />}
                />
            </React.Suspense>
            : <Redirect to="/login" />
    );
};

export default PrivateRoute;