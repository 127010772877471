import React, { createContext, useContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    let localUser = localStorage.user ? JSON.parse(localStorage.user) : null;
    const [user, setUser] = useState(localUser);

    const setTokens = async (data) => {
        if (undefined !== data) {
            // login
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("token", data.token);
            setUser(data);
        } else {
            // logout
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            setUser(null);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                setTokens
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};