import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ReactNotification from 'react-notifications-component'
import './App.scss';
import 'react-notifications-component/dist/theme.css'
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from './PrivateRoute';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const App = () => {
    return (
        <AuthProvider>
            <ReactNotification/>
            <HashRouter>
                <React.Suspense fallback={loading()}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                        <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                        <PrivateRoute path="/" name="Home" loading={loading} />
                    </Switch>
                </React.Suspense>
            </HashRouter>
        </AuthProvider>
    );
};

export default App;
